import HttpRequest from './HttpRequest'

class CourtArbitrationProvider extends HttpRequest {
  findAll () {
    return this.get('/arbitration-court')
  }

  findOne (id) {
    return this.get(`/arbitration-court/${id}`)
  }

  create (payload) {
    return this.post('/arbitration-court', payload)
  }

  update (id, payload) {
    return this.patch(`/arbitration-court/${id}`, payload)
  }

  softDelete (id) {
    return this.delete(`/arbitration-court/${id}`)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/arbitration-court?page=${page}&limit=${limit}`, query)
  }
}

export default CourtArbitrationProvider
