<template>
  <div v-if="isLoaded">
    <DeleteModal
      :is-delete-modal-active.sync="isDeleteModalActive"
      :items="deleteItem"
      @confirm-delete="confirmDelete($event)" />
    <CourtArbitrationAdd
      ref="courtArbitrationAdd"
      :is-sidebar-active.sync="isSidebarActive"
      @add-court-arbitration="addCourtArbitration($event)" />
    <CourtArbitrationDetail
      :is-sidebar-active.sync="isSidebarDetailActive"
      :initial-court-arbitration="initialCourtArbitration"
      :selected-item.sync="selectedItem"
      @delete-item="openDeleteModal($event)"
      @edit-court-arbitration="editCourtArbitration($event)" />
    <CourtArbitrationFillter
      :search.sync="filter.searchName"
      :status-filter.sync="filter.status"
      @fetch-filter="fetchFilter()" />
    <b-card class="mt-2">
      <TableTop
        v-model="pagination.limit"
        label="Add Court Arbitration"
        @active-sidebar="activeSidebar()"
        @change="fetchFilter()" />
      <b-table
        :items="items"
        :fields="fields"
        striped
        hover
        responsive
        @sort-changed="sortFilter($event)"
        @row-clicked="rowClick($event)">
        <template #cell(status)="data">
          <b-badge :variant="$statusValue(data.value)">
            {{ data.value | formatStatusData }}
          </b-badge>
        </template>
        <template #cell(name)="data">
          <span class="font-weight-semibold">{{ data.value }}</span>
        </template>
        <template #cell(remark)="data">
          <div v-if="data && data.item && data.item.remark">
            {{ data.item.remark }}
          </div>
          <div v-else>
            -
          </div>
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.totalDocs"
            :per-page="pagination.limit"
            first-number
            last-number
            class="mb-1 mt-2 mr-1"
            prev-class="prev-item"
            next-class="next-item"
            @change="showCurrentPage($event)">
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18" />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import CourtArbitrationFillter from '@/components/New/CourtArbitration/CourtArbitrationFillter.vue'
import CourtArbitrationAdd from '@/components/New/CourtArbitration/CourtArbitrationAdd.vue'
import CourtArbitrationDetail from '@/components/New/CourtArbitration/CourtArbitrationDetail.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DeleteModal from '@/components/DeleteModal.vue'
import TableTop from '@/components/TableTop.vue'
import CourtArbitrationProvider from '@/resources/CourtArbitrationProvider'

const CourtArbitrationService = new CourtArbitrationProvider()

export default {
  components: {
    CourtArbitrationFillter,
    CourtArbitrationDetail,
    CourtArbitrationAdd,
    DeleteModal,
    TableTop
  },
  data () {
    return {
      isLoaded: false,
      isSidebarActive: false,
      isSidebarDetailActive: false,
      isDeleteModalActive: false,
      filter: {
        searchName: '',
        status: ''
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 0
      },
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'remark', label: 'Remark' },
        { key: 'status', label: 'Status' }
      ],
      items: [],
      initialCourtArbitration: {},
      selectedItem: {},
      deleteItem: {}
    }
  },
  async created () {
    try {
      await this.getCourtArbitration()
    } finally {
      this.isLoaded = true
    }
  },
  methods: {
    fetchFilter () {
      this.setPagination(1)
      this.getCourtArbitration()
    },
    openDeleteModal (val) {
      this.deleteItem = { ...val }
      this.isDeleteModalActive = true
    },
    async editCourtArbitration (val) {
      try {
        const payload = { ...val }
        delete payload.id
        const { data } = await CourtArbitrationService.update(val.id, payload)
        await this.getCourtArbitration()
        this.setInitialCourtArbitrationAndSelectItem(data)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Updated Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Court Arbitration has been updated'
          }
        })
      } catch (err) {
        console.error(err)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 Court Arbitration has been Failed'
          }
        })
      }
    },
    rowClick (val) {
      this.setInitialCourtArbitrationAndSelectItem(val)
      this.isSidebarDetailActive = true
    },
    setInitialCourtArbitrationAndSelectItem (val) {
      this.selectedItem = { ...val }
      this.initialCourtArbitration = { ...val }
    },
    async confirmDelete (val) {
      try {
        this.isSidebarDetailActive = false
        await CourtArbitrationService.softDelete(val.ID)
        await this.getCourtArbitration()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Court Arbitration has been deleted'
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: `😞 Court Arbitration has been Failed ${error}`
          }
        })
      }
    },
    activeSidebar () {
      this.isSidebarActive = true
    },
    async getCourtArbitration () {
      try {
        const page = this.$route?.query?.page || this.pagination?.page || 1
        const limit = this.$route?.query?.limit || this.pagination?.limit || 10
        const data = await CourtArbitrationService.paginate(
          page, limit,
          this.filter
        )
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
      } catch (err) {
        console.error(err)
      }
    },
    async addCourtArbitration (val) {
      try {
        await CourtArbitrationService.create(val)
        await this.getCourtArbitration()
        this.resetFormAndToggleSideBar(this.$refs.courtArbitrationAdd)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Record Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Court Arbitration has been added'
          }
        })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 Court Arbitration has been Failed'
          }
        })
      }
    },
    sortFilter (ctx) {
      console.log(ctx)
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getCourtArbitration()
    },
    setPagination (page, totalDocs) {
      this.$router.replace(`court-arbitration?page=${page}`)
      this.pagination.totalDocs = totalDocs || this.pagination.totalDocs
      this.pagination.page = page || this.pagination.page
    },
    resetFormAndToggleSideBar (ref) {
      this.isSidebarActive = !this.isSidebarActive
      if (ref && ref.resetForm()) ref.resetForm()
    }
  }
}
</script>

<style lang="scss" scoped>
.card-body {
  padding: 0;
}
</style>
